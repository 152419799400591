<template>
  <header>
    <div class="l-content">
      <!-- <el-button
        plain
        icon="el-icon-menu"
        size="mini"
        @click="handMenu"
      ></el-button> -->
        <div style="display: flex;align-items: center;height: 44px">
            <img  src="../assets/bm-logo.png"
                  style="margin-right: 8px;"
                  alt="" />
        </div>
      <img v-if="false" src="../assets/me_app.png" alt="" />
      <h2 v-if="false" style="color: #333">我的应用</h2>
    </div>
    <div class="r-content">
      <el-input
        class="sou"
        prefix-icon="el-icon-search"
        v-model="state"
        @change="handleSelect"
        placeholder="搜索应用"
      ></el-input>
        <el-button type="primary">搜索</el-button>
      <img v-if="false" class="img1" src="../assets/me_ti.png" alt="" />
      <img class="user-head" src="../assets/me_tou.png" alt="" />
      <!-- <span class="name">慧康养团队</span> -->
      <!-- <el-menu default-active="2" mode="vertical">
        <el-submenu index="'1'" style="color: #bfcbd9; float: right">
          <template slot="title">{{ this.$store.state.phone }}</template>
          <el-menu-item index="1-1" @click="logonOut">退出登录</el-menu-item>
          <el-menu-item index="1-2">个人中心</el-menu-item>
        </el-submenu>
      </el-menu> -->

        <span class="el-dropdown-link">
          {{ this.$store.state.phone }}
        </span>
        <span @click="logonOut">安全退出</span>

      <el-dropdown v-if="false">
        <span class="el-dropdown-link">
          {{ this.$store.state.phone }}
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-plus">
            <span style="display: inline-block" @click="logonOut"
              >退出登录</span
            >
          </el-dropdown-item>
          <!-- <el-dropdown-item icon="el-icon-circle-plus"
            >个人中心</el-dropdown-item
          > -->
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </header>
</template>

<script>
import { getApp } from "../api/data";

export default {
  data() {
    return {
      state: "",
    };
  },
  methods: {
    // 搜索应用
    handleSelect(item) {
        this.$store.commit("SetSearchParams", {appName: item})
    },
    logonOut() {
      // localStorage.removeItem("phone");
      // localStorage.removeItem("token");
      // localStorage.removeItem("ShiM");
      // localStorage.removeItem("tokenTime");
      localStorage.clear();
      this.$router.push("/login");
    },
    // handMenu() {
    //   this.$store.commit("collapseMenu");
    // },
  },
};
</script>

<style lang="scss" scoped>
header {
  display: flex;
  height: 100%;
  align-content: center;
  justify-content: space-between;
  // padding: 5px;
  .l-content {
    display: flex;
    width: 50%;
    align-items: center;
    align-content: center;
    > img {
      width: 30px;
      height: 30px;
      display: block;
      margin-right: 10px;
    }
    // .el-button {
    //   margin-right: 10px;
    //   height: 80%;
    // }
  }
  .r-content {
    display: flex;
    align-items: center;
    .user-head {
      width: 28px;
      margin-left: 41px;
      margin-right: 8px;
    }
    .el-dropdown-link {
      cursor: pointer;
      color: #000000;
      margin-right: 32px;
    }
    // width: 50%;
    ::v-deep .el-input__inner {
      border: none;
      background: #fafafa;
    }
    .sou {
      width: 228px;
    }
    .img1 {
      margin: 0 10px;
    }
    .name {
      font-size: 14px;
      color: #333;
      display: inline-block;
      white-space: nowrap;
      margin: 0 5px;
    }
  }
}
</style>