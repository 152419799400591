<template>
  <el-menu
    class="el-menu-vertical-demo"
    :collapse="isCollapse"
    text-color="#fff"
    active-text-color="#ffd064"
  >
    <div class="h" v-show="!isCollapse">
      <img width="100%" src="../assets/left-logo.png" alt="" />
    </div>
    <!-- <h3 class="h" v-show="isCollapse">后台</h3> -->
    <div class="m">
      <!-- <div
        v-for="item in noChildren"
        :key="item.path"
        @click="clickMenu(item)"
        :class="['items', { actives: activem == item.label }]"
      > -->
      <div
        v-for="item in noChildren"
        :key="item.path"
        @click="clickMenu(item)"
        :class="['items', { actives: activem == item.label }]"
      >
        <img v-if="activem == item.label" :src="item.icon2" alt="" />
        <img v-else :src="item.icon" alt="" />
        <div>{{ item.label }}</div>
      </div>
    </div>
  </el-menu>
</template>

<script>
export default {
  data() {
    return {
      activem: "工具箱",
      menu: [
        {
          path: "/home",
          name: "home",
          label: "工具箱",
          icon: require("../assets/leftg_w.png"),
          icon2: require("../assets/leftg_red.png"),
          url: "Home/Home",
        },
        {
          path: "/mall",
          name: "mall",
          label: "消息",
          icon: require("../assets/leftMsg_w.png"),
          icon2: require("../assets/leftMsg_red.png"),
          url: "",
        },
        {
          path: "/login",
          name: "login",
          label: "我的",
          icon: require("../assets/left_me_w.png"),
          icon2: require("../assets/left_me_red.png"),
          url: "Login",
        },
      ],
    };
  },
  methods: {
    clickMenu(item) {
      // 点击样式
      // this.activem = item.label;
    },
  },
  computed: {
    noChildren() {
      return this.menu.filter((item) => !item.children);
    },
    hasChildren() {
      return this.menu.filter((item) => item.children);
    },
    isCollapse() {
      return this.$store.state.tab.isCollapse;
    },
  },
};
</script>

<style lang="scss" scoped>
.m {
  width: 130px;
  margin: auto;
}
.items {
  background: url("../assets/left_mo.png");
  margin: 20px auto;
  width: 100%;
  text-align: center;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
}
.items.actives {
  background: url("../assets/left_xuan.png");
  width: 100%;
  color: #c13a75;
}
::v-deep .el-menu-item ::v-deep .is-active {
  background: #c13a75;
  height: 100%;
}
::v-deep .el-menu-item,
.el-submenu__title {
  height: none;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  height: 100%;
  background-image: linear-gradient(0deg, #f166a3 0%, #c13a75 100%);
}
.h {
  text-align: center;
  color: lightblue;
}
</style>