<template>
  <div class="tabs">
    <el-tag></el-tag>
    <el-button
      v-model="a"
      @click="add(i)"
      v-for="(item, i) in this.arr"
      :key="i"
      >{{ item }}</el-button
    >
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>