<template>
  <el-container style="height: 100%">
    <el-aside width="auto">
      <common-aside v-if="false"/>
    </el-aside>
    <el-container>
      <el-header style="height: 70px">
        <common-header />
      </el-header>
      <!-- <comon-tag /> -->
      <el-main><router-view /></el-main>
    </el-container>
  </el-container>
</template>

<script>
import CommonAside from "../components/CommonAside.vue";
import CommonHeader from "../components/CommonHeader.vue";
import ComonTag from "../components/ComonTag.vue";
export default {
  components: { CommonAside, CommonHeader, ComonTag },
  name: "Main",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.el-header {
  background: #fff;
}
.el-main {
  padding: 0;
}
</style>